import { Controller } from "@hotwired/stimulus"

import { AddressFinder } from "@ideal-postcodes/address-finder-bundled";

// Connects to data-controller="address-lookup"
export default class extends Controller {

  // Load Ideal Postcode's Address Finder only when data-controller is set to address-lookup
  connect() {
    const controller = AddressFinder.setup({
      inputField: "#address_lookup",
      apiKey: "ak_kk3ye250IbiRrFVZQDcT3C4Akbdc5",
      autocomplete: "off",
      outputFields: {
        line_1: "#address_address1",
        line_2: "#address_address2",
        post_town: "#address_address3",
        county: "#address_county",
        postcode: "#address_postcode",
        country: "#address_country"
      },
    });
  }

}
