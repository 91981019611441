import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="game-key"
export default class extends Controller {
  connect() {
  }

  onKeyup(event) {
    var steamregex = /^[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}$/i;
    var originregex = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/i;
    var uplayoriginregex = /^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/i;
    var epicgamesregex = /^[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}$/i;
    var gogregex = /^[A-Z0-9]{18}$/i;

    if (event.target.value.match(steamregex)) {
      document.getElementById('game_platform').value = "Steam";
    } else if (event.target.value.match(originregex)) {
      document.getElementById('game_platform').value = "Origin";
    } else if (event.target.value.match(uplayoriginregex)) {
      document.getElementById('game_platform').value = "Origin/Uplay";
    } else if (event.target.value.match(epicgamesregex)) {
      document.getElementById('game_platform').value = "Epic Games";
    } else if (event.target.value.match(gogregex)) {
      document.getElementById('game_platform').value = "GOG";
    }
  }
}
