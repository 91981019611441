import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  connect() {
  }

  show(event) {
    document.getElementById("search").style.display = "block";
    event.preventDefault();
  }
}
