// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

import * as Turbo from '@hotwired/turbo'
Turbo.start()

import TurboPower from 'turbo_power'
TurboPower.initialize(Turbo.StreamActions)

import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

const application = Application.start()

import Clipboard from 'stimulus-clipboard'
application.register('clipboard', Clipboard)

import Sortable from 'stimulus-sortable'
application.register('sortable', Sortable)

const controllers = import.meta.glob('../javascript/controllers/**/*_controller.js', { eager: true })
registerControllers(application, controllers)
