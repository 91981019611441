import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    token: String,
  };

  get targetElement() {
    return document.getElementById(this.tokenValue);
  }

  remove() {
    this.targetElement.remove()
  }
  
}
